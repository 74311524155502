import { graphql, Link } from "gatsby";
import React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import { grey } from "../shared/colors";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: solid 1px ${grey};
  margin: 0 0 20px 0;
`;

const ArticleDate = styled.h2`
  background-color: ${grey};
  padding: 10px;
  font-size: 11pt;
  font-weight: 500;
  margin: 5px;
`;

const ArticleTitle = styled.h3`
  font-size: 10pt;
  margin: 15px;
`;

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  &:hover ${ArticleTitle} {
    text-decoration: underline;
  }
`;


const BlogBox = ({ date, title, slug }: {date: string, title: string, slug: string}) => (
    <ListItem>
      <StyledLink to={slug}>
        <ArticleDate>{date}</ArticleDate>
        <ArticleTitle>{title}</ArticleTitle>
      </StyledLink>
    </ListItem>
  );

export default ({pageContext, data}: {pageContext: any, data: any}) => {
    const blogs = data.articles.edges;
    return (
        <Layout title="eDubs blog" transparent={true} header={'/assets/images/uploads/beach-pier.jpg'} >
            <Seo title='eDubs blog' />
            <Wrapper>
                <List>
                {blogs.map((blog: any, index: number) => (
                    <BlogBox key={index}
                    date={blog.node.frontmatter.date}
                    title={blog.node.frontmatter.title}
                    slug={blog.node.frontmatter.slug}
                    />
                ))}
                </List>
            </Wrapper>
        </Layout>)
}

export const query = graphql`
  query newsArticleQuery($skip: Int!, $limit: Int!) {
    articles: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blogs)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            slug
          }
        }
      }
    }
  }
`;
